import React, { FC, useState } from 'react';
import { IAccountInfo } from 'react-aad-msal';
import { NavLink } from 'react-router-dom';
import { LogoutFunction } from '../../authProviders/authProvider';
import { useUserStore } from './UserStoreContext';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Menu, MenuItem, Select } from '@mui/material';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import { environment } from './env';
import HomeIcon from '@mui/icons-material/Home';
import { t } from 'i18next';
import VKIService from '../Services/VKIService';

export type HeaderProps = {
	accountInfo: IAccountInfo;
	logout: LogoutFunction;
};

export const Header: FC<HeaderProps> = ({ accountInfo, logout }) => {
	const { readonly, setReadonly } = useUserStore();
	const [showUserRawInfo, setShowUserRawInfo] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [taal, setTaal] = useState("nl");
	const [selectedItem, setSelectedItem] = useState('nl');
	function handleShowUserRawInfo(): void {
		setShowUserRawInfo(!showUserRawInfo);
	}

	function handleReadonly(): void {
		setReadonly(!readonly);
	}

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
  
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
	  setAnchorEl(event.currentTarget);

	};
	const useStyles = makeStyles({
		button: {
			borderRadius: '50%',
			padding: '10px',
			minWidth: 'unset',
			width: '40px',
			height: '40px',
			backgroundColor: '#f0f0f0',
			'&:hover': {
				backgroundColor: '#e0e0e0',
			},
		},
	});
	
	const classes = useStyles();
	const handleClose = () => {
	  setAnchorEl(null);
	};
	return (
		<>
			<nav className="navbar navbar-expand-lg navbar-light mb-2">
				
				<span className="navbar-brand"><NavLink className="nav-link"  to="/"><img src={'/' + environment.AD_B2C_ApplicationId + '.png'} className='logo' />&nbsp;&nbsp;{t('vkiportaal') ? t('vkiportaal') : "VKI Portaal"}&nbsp;<HomeIcon fontSize='large' /></NavLink></span>

				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav mr-auto">
						<li className="nav-item">

						</li>
					</ul>
					<div className="form-inline my-2 my-lg-0">

						<span className="nav-link" onClick={handleShowUserRawInfo}>
							
						</span>
						<strong>{accountInfo.account.name}&nbsp;</strong>
						<Button
							id="basic-button"
							aria-controls={open ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							variant="contained"
							color="primary"
							onClick={handleClick}
							className={classes.button}
						>
							<SettingsIcon />
						</Button>
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
							'aria-labelledby': 'basic-button',
							}}
						>
							<MenuItem onClick={async () => 
							{ 
								setOpen(true);

							}}>{t('Taal')}</MenuItem>
							<MenuItem onClick={async () => 
							{ 
								setAnchorEl(null); 
								logout();

							}}>{t('Uitloggen')}</MenuItem>
							<MenuItem onClick={async () => 
							{ 
								setAnchorEl(null); 
								//logout();
								window.location.href = 'https://vkiportaal.b2clogin.com/vkiportaal.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_WachtwoordWijzigen&client_id=777eb746-1b1b-4ce2-850c-0ea009913183&nonce=defaultNonce&redirect_uri=' + environment.AD_B2C_ReactRedirectUri + '&scope=openid&response_type=id_token&prompt=login'
							}}>{t('wachtwoordwijzigen')}</MenuItem>
						</Menu>
					</div>
				</div>
			</nav>
			<hr></hr>
			<>

                <Dialog open={isOpen} >
                <DialogTitle>{t('Taal')}</DialogTitle>
                <DialogContent>
                    <p > {t('taalveranderen')}</p>
					<Select

						value={selectedItem}
						autoWidth={true}
						onChange={(event) => setSelectedItem(event.target.value)}
						displayEmpty
						size="small"	
						style={{ marginBottom: '1rem' }}
						>
						<MenuItem value="nl" selected >
						{t('nederlands')}
						</MenuItem>
						<MenuItem value="de" >
						{t('duits')}
						</MenuItem>
					</Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(() => {VKIService.changeLanguage(selectedItem, accountInfo.jwtIdToken, accountInfo.account.idToken.sub).then(()=>{window.location.reload()}); setOpen(false); localStorage.setItem("VKILanguage", selectedItem); })} variant="contained" color="primary"  autoFocus>
                    {t('ok')}
                    </Button>
                </DialogActions>
                </Dialog>
            </>
		</>
	);
};
